<template>
  <div
    class="fixed right-4 z-10"
    :class="
      global.loadedMore ? 'top-[66%] lg:top-[75%]' : 'top-[70%] md:top-[80%]'
    "
  >
    <div
      v-if="global.loadedMore"
      class="p-2 border border-primary-primary rounded-full cursor-pointer mb-2 rotate-[270deg] hover:bg-primary-primary scroll-icon-wrapper"
      @click.prevent="scrollTop"
    >
      <NuxtIcon name="arrow" filled class="scroll-top-icon" />
    </div>
    <!-- <div
      v-if="showLiveChat"
      class="bg-primary-primary p-2 rounded-full cursor-pointer"
    >
      <NuxtIcon name="live-chat" filled class="live-chat-icon" />
    </div> -->
  </div>
</template>

<script setup>
import { useGlobalStore } from '~/store/global'

const global = useGlobalStore()
const route = useRoute()

// const showLiveChat = computed(() => {
//   return route.name.includes('-slug') || route.name.includes('work-')
// })

const scrollTop = () => {
  window.scroll(0, 0)
}

watch(
  () => route.path,
  () => {
    global.loadedMore = false
  }
)
</script>

<style lang="scss" scoped>
:deep(.live-chat-icon) {
  svg {
    width: 30px;
    height: 30px;
  }
}
:deep(.scroll-top-icon) {
  svg {
    width: 28px;
    height: 28px;
  }
}

.scroll-icon-wrapper {
  &:hover {
    :deep(.scroll-top-icon) {
      svg {
        path {
          fill: white !important;
        }
      }
    }
  }
}
</style>
